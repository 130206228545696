<div
    class="nav-panel"
    [ngClass]="{'nav-panel--sticky': stickyMode}"
    #element
>
    <div class="nav-panel__container container">
        <div class="nav-panel__row">
            <div *ngIf="logo" class="nav-panel__logo">
                <a [routerLink]="root.home()" style="color: #FFF;">
                    FlowCloud Shop
                </a>
            </div>

            <div *ngIf="departments" class="nav-panel__departments">
                <app-header-departments></app-header-departments>
            </div>

            <!-- .nav-links -->
            <!-- <app-header-links class="nav-panel__nav-links nav-links"></app-header-links> -->
            <!-- .nav-links / end -->

            <div class="nav-panel__indicators">
                <app-indicator
                    *ngIf="search"
                    icon="search-20"
                    iconWhenOpen="cross-20"
                    trigger="click"
                    (stateChanges)="$event === true ? searchField.focus() : ''"
                    #indicator="appIndicator"
                >
                    <div class="drop-search">
                        <form action="./shop/catalog" class="drop-search__form">
                            <input
                                class="drop-search__input"
                                name="search" (keydown.escape)="indicator.close()"
                                placeholder="Buscar productos"
                                aria-label="Buscador de productos"
                                type="text"
                                autocomplete="off" #searchField 
                            >
                            <button class="drop-search__button drop-search__button--submit" type="submit">
                                <app-icon name="search-20" size="20"></app-icon>
                            </button>
                        </form>
                    </div>
                </app-indicator>

                <!-- <app-indicator [link]="root.wishlist()" icon="heart-20" [counter]="wishlist.count$|async"></app-indicator> -->

                <app-indicator link="./shop/cart" icon="cart-20" trigger="click" [counter]="cart.quantity$|async" #indicator="appIndicator">
                    <app-header-dropcart (closeMenu)="indicator.close(true)"></app-header-dropcart>
                </app-indicator>

                <app-indicator link="./account" icon="person-20" trigger="click" #indicator="appIndicator">
                    <app-account-menu (closeMenu)="indicator.close(true)"></app-account-menu>
                </app-indicator>
            </div>
        </div>
    </div>
</div>
