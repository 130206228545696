<div class="block">
    <div class="container">
        <div class="not-found">
            <div class="not-found__404">
                Oops! Error 404
            </div>

            <div class="not-found__content">
                <h1 class="not-found__title">Página no encontrada</h1>

                <p class="not-found__text">
                    Vuelve a intentar tu búsqueda.<br>
                    Prueba utilizando algún otro filtro.
                </p>

                <form class="not-found__search">
                    <input type="text" class="not-found__search-input form-control" placeholder="Seguir buscando">
                    <button type="submit" class="not-found__search-button btn btn-primary">Buscar</button>
                </form>

                <p class="not-found__text">
                    Puede ir al inicio del sitios
                </p>

                <a class="btn btn-secondary btn-sm" href="./">Vayámos al inicio</a>
            </div>
        </div>
    </div>
</div>
