<div class="site-header">
    <app-header-topbar></app-header-topbar>

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo">
            <a routerLink="./">
                FlowCloud Shop
            </a>
        </div>
        <div class="site-header__search">
            <app-header-search></app-header-search>
        </div>
        <div class="site-header__phone">
            <div class="site-header__phone-title">Servicio al cliente</div>
            <div class="site-header__phone-number">{{ store.primaryPhone }}</div>
        </div>
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav
            [departments]="layout === 'classic'"
            [logo]="layout === 'compact'"
            [search]="layout === 'compact'"
            stickyMode="pullToShow"
        ></app-header-nav>
    </div>
</div>
