import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service'

import * as jwt_decode from 'jwt-decode';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    USER_INFO:any = {};

    ALERT:any = {
        'show': false,
        'message': null
    };

    constructor(
    	private authService: AuthService, 
    	private router: Router
    ) { }

    ngOnInit() {
    	this.getToken();
    }

    login(form): void {
    	this.authService.login(form.value).subscribe(res => {
            this.ALERT.show = false;
            this.ALERT.message = null;
            
    		//this.router.navigateByUrl('/account/profile');
            location.reload();
    	}, error => {
            this.ALERT.show = true;
            this.ALERT.message = error.error.message;       
        });
    }

    logout(): void {
    	this.authService.logout();
        location.reload();
    }

    private getToken(): string {
    	let accessToken: string = this.authService.getToken();

    	if (null === accessToken) {
    		this.USER_INFO = {};
    	} else {
	    	let decode: any = jwt_decode(accessToken);
	    	this.USER_INFO.NOMBRE = decode.NOMBRES;
	    	this.USER_INFO.PRIMER_APELLIDO = decode.PRIMER_APELLIDO;
	    	this.USER_INFO.SEGUNDO_APELLIDO = decode.SEGUNDO_APELLIDO;
	    	this.USER_INFO.EMAIL = decode.EMAIL;
	    }

		return localStorage.getItem("ACCESS_TOKEN");
	}
}
