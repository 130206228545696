import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../interfaces/user';
import { UserRegister } from '../interfaces/user-register';
import { JwtResponse } from '../interfaces/jwt-response';
import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

// import * as bcrypt from 'bcryptjs';

//@Injectable({
//  providedIn: 'root'
//})

@Injectable()
export class AuthService {
	AUTH_SERVER: string = 'https://api.flowcloud.com.mx/public/api/v1/usuario';
	// AUTH_SERVER: string = 'http://api.arcanet.com/api/v1/usuario';

	authSubject = new BehaviorSubject(false);
	private token: string;

  	constructor(private HttpClient: HttpClient) { }

	login(user: User):Observable<JwtResponse> {
		return this.HttpClient.post<JwtResponse>(`${this.AUTH_SERVER}/cliente/login`, user)
			.pipe(tap(
				(res:JwtResponse) => {
					console.log("res", res);
					if(res) {
						this.guardarToken(res.token);
					}
				}, 
				(error:any) => {
					console.log("error", error);
				}
			)
		)
	}

    register(user: UserRegister):Observable<JwtResponse> {
	  	return this.HttpClient.post<JwtResponse>(`${this.AUTH_SERVER}/registro`, user)
	  		.pipe(tap( 
	  			(res:JwtResponse) => {
	  				if(res) {
	  					this.guardarToken(res.token);
	  				}
	  			}, 
				(error:any) => {
					console.log("error", error);
				}
	  		)
	  	)
	}

    confirmarCompra() {
	  	return this.HttpClient.post<any>(`${this.AUTH_SERVER}/shop/mail/ventas`, {})
	  		.pipe(tap( 
	  			(res:JwtResponse) => {
	  				if(res) {
	  					this.guardarToken(res.token);
	  				}
	  			}, 
				(error:any) => {
					console.log("error", error);
				}
	  		)
	  	)
	}

	logout(): void {
		console.log("logout");
		this.token = '';
		localStorage.removeItem("ACCESS_TOKEN");
	}

	loggedIn() {
		return !!localStorage.getItem("ACCESS_TOKEN");
	}

	private guardarToken(token: string) {
		localStorage.setItem("ACCESS_TOKEN", token);
		this.token = token;
	}

	getToken(): string {
		if (!this.token) {
			this.token = localStorage.getItem("ACCESS_TOKEN");
		}
		return this.token;
	}
}
