import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

	constructor(private authService: AuthService) { }

	intercept(req, next) {
		const tokenizeReq = req.clone({
			'setHeaders': {
				'Authorization': `_FCT_ ${this.authService.getToken()}`,
				'Content-Type': 'application/json'
	      		//'Access-Control-Allow-Origin': 'https://stock.flowcloud.com.mx'
			}
		});
		return next.handle(tokenizeReq);
	}

}
