<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery">
            <div class="product-gallery" *ngIf="showGallery">
                <div class="product-gallery__featured">
                    <owl-carousel-o [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)" appOwlPreventClick #featuredCarousel>
                        <ng-template *ngFor="let image of images" carouselSlide [id]="image.id">
                            <a appClick (click)="openPhotoSwipe($event, image);" [href]="image.url" target="_blank">
                                <img [src]="image.url" alt="" #imageElement>
                            </a>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="product-gallery__carousel">
                    <owl-carousel-o [options]="thumbnailsCarouselOptions" [appFakeSlides]="images.length" #fakeSlides="appFakeSlides" appOwlPreventClick #thumbnailsCarousel>
                        <ng-template *ngFor="let image of images" carouselSlide [id]="image.id">
                            <span class="product-gallery__carousel-item"
                               [ngClass]="{'product-gallery__carousel-item--active': image.active}"
                               appClick (click)="featuredCarousel.to(image.id); setActiveImage(image)">
                                <img class="product-gallery__carousel-image" [src]="image.url" alt="">
                            </span>
                        </ng-template>
                        <ng-template *ngFor="let i of fakeSlides.slides" carouselSlide [id]="'fake-slide-'+i"></ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Wishlist"
                        appClick (click)="addToWishlist()"
                        [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Compare"
                        appClick (click)="addToCompare()"
                        [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button>
            </div>
            <h1 class="product__name">{{ product.name }}</h1>
            <div class="product__rating">
                <div class="product__rating-stars">
                    <app-rating [value]="product.rating"></app-rating>
                </div>
                <div class="product__rating-legend">
                    <a href="#" appClick>{{ product.reviews }} vistas</a><!-- <span>/</span><a href="" appClick>Write A Review</a> -->
                </div>
            </div>
            <div class="product__description" [innerHtml]="product.customFields[0].description"></div>
            <!-- <ul class="product__features">
                <li>Speed: 750 RPM</li>
                <li>Power Source: Cordless-Electric</li>
                <li>Battery Cell Type: Lithium</li> 
                <li>Voltage: 20 Volts</li>
                <li>Battery Capacity: 2 Ah</li>
            </ul> -->
            <ul class="product__meta">
                <li class="product__meta-availability">
                    Disponibilidad: 
                    <span class="text-{{ product.availability=='in-stock'?'success':'warning' }}">
                        {{ product.availability=='in-stock'?'En stock':'Sin stock' }} ({{ product.customFields[0].stock }})
                    </span>
                </li>
                <!-- <li>Brand: <a href="" appClick>Wakita</a></li> -->
                <li>SKU: {{ product.customFields[0].sku }} </li>
            </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
                Disponibilidad: <span class="text-success">En Stock</span>
            </div>

            <div class="product__prices">
                <ng-container *ngIf="product.compareAtPrice">
                    <span class="product__new-price">{{ product.price|currencyFormat }}</span>{{ ' ' }}
                    <span class="product__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
                </ng-container>
                <ng-container *ngIf="!product.compareAtPrice">
                    {{ product.price|currencyFormat }}
                </ng-container>
            </div>
            <!-- .product__options -->
            <form class="product__options">
                <!-- <div class="form-group product__option">
                    <label class="product__option-label">Color</label>
                    <div class="input-radio-color">
                        <div class="input-radio-color__list">
                            <label class="input-radio-color__item input-radio-color__item--white" style="color: #fff;" data-toggle="tooltip" title="White">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ffd333;" data-toggle="tooltip" title="Yellow">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ff4040;" data-toggle="tooltip" title="Red">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item input-radio-color__item--disabled" style="color: #4080ff;" data-toggle="tooltip" title="Blue">
                                <input type="radio" name="color" disabled>
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group product__option">
                    <label class="product__option-label">Material</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label>
                                <input type="radio" name="material">
                                <span>Metal</span>
                            </label>
                            <label>
                                <input type="radio" name="material">
                                <span>Wood</span>
                            </label>
                            <label>
                                <input type="radio" name="material" disabled>
                                <span>Plastic</span>
                            </label>
                        </div>
                    </div>
                </div> -->
                <div class="form-group product__option">
                    <label class="product__option-label">Cantidad</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart">
                            <button type="button" class="btn btn-primary btn-lg"
                                    [ngClass]="{'btn-loading': addingToCart}"
                                    appClick (click)="addToCart()">Agregar al carrito</button>
                        </div>
                        <!-- <div class="product__actions-item product__actions-item--wishlist">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Wishlist"
                                    [ngClass]="{'btn-loading': addingToWishlist}"
                                    appClick (click)="addToWishlist()">
                                <app-icon name="wishlist-16" size="16"></app-icon>
                            </button>
                        </div>
                        <div class="product__actions-item product__actions-item--compare">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Compare"
                                    [ngClass]="{'btn-loading': addingToCompare}"
                                    appClick (click)="addToCompare()">
                                <app-icon name="compare-16" size="16"></app-icon>
                            </button>
                        </div> -->
                    </div>
                </div>
            </form>
            <!-- .product__options / end -->
        </div>
        <!-- .product__end -->
        <!-- <div class="product__footer">
            <div class="product__tags tags">
                <div class="tags__list">
                    <a href="" appClick>Mounts</a>
                    <a href="" appClick>Electrodes</a>
                    <a href="" appClick>Chainsaws</a>
                </div>
            </div>

            <div class="product__share-links share-links">
                <ul class="share-links__list">
                    <li class="share-links__item share-links__item--type--like"><a href="" appClick>Like</a></li>
                    <li class="share-links__item share-links__item--type--tweet"><a href="" appClick>Tweet</a></li>
                    <li class="share-links__item share-links__item--type--pin"><a href="" appClick>Pin It</a></li>
                    <li class="share-links__item share-links__item--type--counter"><a href="" appClick>4K</a></li>
                </ul>
            </div>
        </div> -->
    </div>
</div>
