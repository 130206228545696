<div class="account-menu">
    <form #frmLogin="ngForm" (ngSubmit)="login(frmLogin)" class="account-menu__form" *ngIf="(USER_INFO | json) == '{}'">
        <div class="alert alert-warning text-center" role="alert" *ngIf="ALERT.show">
          {{ ALERT.message }}
        </div>
        <div class="account-menu__form-title">Iniciar sesión</div>
        <div class="form-group">
            <label for="header-signin-email" class="sr-only">Correo electrónico</label>
            <input id="header-signin-email" type="email" class="form-control form-control-sm" name="email" required="true" ngModel placeholder="Correo electrónico">
        </div>
        <div class="form-group">
            <label for="header-signin-password" class="sr-only">Contraseña</label>
            <div class="account-menu__form-forgot">
                <input id="header-signin-password" type="password" class="form-control form-control-sm" name="password" required="true" ngModel placeholder="Contraseña">
                <a href="" class="account-menu__form-forgot-link">Olvidé mi contraseña</a>
            </div>
        </div>
        <div class="form-group account-menu__form-button">
            <button type="submit" class="btn btn-primary btn-sm">Iniciar sesión</button>
        </div>
        <div class="account-menu__form-link"><a routerLink="/account/login" (click)="closeMenu.emit()">Crear cuenta</a></div>
    </form>
    <div class="account-menu__divider" *ngIf="(USER_INFO | json) != '{}'"></div>
    <a routerLink="/account/dashboard" class="account-menu__user" (click)="closeMenu.emit()" *ngIf="(USER_INFO | json) != '{}'">
        <div class="account-menu__user-avatar">
            <img src="assets/images/avatars/avatar-3.jpg" alt="">
        </div>
        <div class="account-menu__user-info">
            <div class="account-menu__user-name">{{ USER_INFO.NOMBRE }} {{ USER_INFO.PRIMER_APELLIDO }} {{ USER_INFO.SEGUNDO_APELLIDO }}</div>
            <div class="account-menu__user-email">{{ USER_INFO.EMAIL }}</div>
        </div>
    </a>
    <div class="account-menu__divider" *ngIf="(USER_INFO | json) != '{}'"></div>
    <ul class="account-menu__links" *ngIf="(USER_INFO | json) != '{}'">
        <li><a routerLink="/account/profile" (click)="closeMenu.emit()">Editar perfil</a></li>
        <!-- <li><a routerLink="/account/orders" (click)="closeMenu.emit()">Mis compras</a></li>
        <li><a routerLink="/account/addresses" (click)="closeMenu.emit()">Domicilios</a></li>
        <li><a routerLink="/account/password" (click)="closeMenu.emit()">Contraseña</a></li> -->
    </ul>
    <div class="account-menu__divider" *ngIf="(USER_INFO | json) != '{}'"></div>
    <ul class="account-menu__links" *ngIf="(USER_INFO | json) != '{}'">
        <li><a href="#" (click)="logout()">Cerrar sesión</a></li>
    </ul>
</div>
