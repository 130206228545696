<div class="site-footer__widget footer-contacts">
    <!-- <h5 class="footer-contacts__title">Contacto</h5> -->

<!--     <div class="footer-contacts__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in feugiat lorem. Pellentque ac placerat tellus.
    </div> -->

    <ul class="footer-contacts__contacts">
        <li *ngIf="store.address != null"><i class="footer-contacts__icon fas fa-globe-americas"></i> {{ store.address }}</li>
        <li *ngIf="store.email != null"><i class="footer-contacts__icon far fa-envelope"></i> {{ store.email }}</li>
        <li *ngIf="store.phone.length > 0"><i class="footer-contacts__icon fas fa-mobile-alt"></i> {{ store.phone.join(', ') }}</li>
        <li *ngIf="store.hours != null"><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li>
    </ul>
</div>
