<app-block-slideshow></app-block-slideshow>

<app-block-features layout="boxed"></app-block-features>

<!--app-block-products-carousel
    header="Productos destacados"
    layout="grid-5"
    [rows]="2"
    [loading]="featuredProducts.loading"
    [products]="featuredProducts.products"
    [groups]="featuredProducts.groups"
    (groupChange)="groupChange(featuredProducts, $event)"
></app-block-products-carousel>

<app-block-banner></app-block-banner-->

<!-- <app-block-products header="Los productos mas vendidos" layout="large-last" [products]="bestsellers$|async"></app-block-products> -->

<!-- <app-block-categories header="Categorías populares" layout="compact" [categories]="popularCategories$|async"></app-block-categories> -->

<!--app-block-products-carousel
    header="New Arrivals"
    layout="grid-5"
    [rows]="1"
    [loading]="latestProducts.loading"
    [products]="latestProducts.products"
    [groups]="latestProducts.groups"
    (groupChange)="groupChange(latestProducts, $event)"
></app-block-products-carousel-->

<!-- <app-block-posts header="Noticias" layout="grid-nl" [posts]="posts"></app-block-posts> -->

<!-- <app-block-brands [brands]="brands$|async"></app-block-brands> -->

<!-- <app-block-product-columns>
    <app-block-product-columns-item header="Productos destacados" [products]="columnTopRated$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Ofertas especiales" [products]="columnSpecialOffers$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Mas vendidos" [products]="columnBestsellers$|async"></app-block-product-columns-item>
</app-block-product-columns>
 -->