<!-- .dropcart -->
<div class="dropcart">
    <div class="dropcart__empty" *ngIf="!(cart.items$|async).length">
        Tu carrito está vacío
    </div>
    <ng-container *ngIf="(cart.items$|async).length">
        <div class="dropcart__products-list">
            <div *ngFor="let item of cart.items$|async" class="dropcart__product">
                <div class="dropcart__product-image">
                    <a [routerLink]="root.product(item.product)" *ngIf="item.product.images?.length" (click)="closeMenu.emit()">
                        <img [src]="item.product.images[0]" alt="">
                    </a>
                </div>
                <div class="dropcart__product-info">
                    <div class="dropcart__product-name">
                        <a [routerLink]="root.product(item.product)" (click)="closeMenu.emit()">{{ item.product.name }}</a>
                    </div>

                    <ul *ngIf="item.options.length" class="dropcart__product-options">
                        <li *ngFor="let option of item.options">{{ option.name }}: {{ option.value }}</li>
                    </ul>
                    <div class="dropcart__product-meta">
                        <span class="dropcart__product-quantity">{{ item.quantity }}</span> ×
                        <span class="dropcart__product-price">{{ item.product.price|currencyFormat }}</span>
                    </div>
                </div>

                <button type="button" class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="remove(item)" [ngClass]="{'btn-loading': removedItems.includes(item)}">
                    <app-icon name="cross-10" size="10"></app-icon>
                </button>
            </div>
        </div>

        <div class="dropcart__totals">
            <table>
                <tr *ngIf="(cart.totals$|async).length">
                    <th>Subtotal</th>
                    <td>{{ cart.subtotal$|async|currencyFormat }}</td>
                </tr>
                <tr *ngFor="let total of cart.totals$|async">
                    <th>{{ total.title }}</th>
                    <td>{{ total.price|currencyFormat  }}</td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td>{{ cart.total$|async|currencyFormat }}</td>
                </tr>
            </table>
        </div>

        <div class="dropcart__buttons">
            <a class="btn btn-secondary" [routerLink]="root.cart()" (click)="closeMenu.emit()">Ver carrito</a>
            <a class="btn btn-primary" [routerLink]="root.checkout()" (click)="closeMenu.emit()">Comprar</a>
        </div>
    </ng-container>
</div>
<!-- .dropcart / end -->
